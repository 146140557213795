<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="courses-title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Home' }">{{
              $t("courses.home")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $t("courses.courses") }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{
              $t("courses.students_list")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="courses-list">
          <div>
            <el-row>
              <el-col :span="12">
                <h3>{{ $t("courses.students_list") }}</h3>
              </el-col>
            </el-row>
            <el-table
              :data="students"
              style="width: 100%"
              :empty-text="$t('courses.empty-text')"
            >
              <el-table-column
                prop="student_name"
                :label="$t('courses.name')"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="student_email"
                :label="$t('courses.email')"
                width="200"
              >
              </el-table-column>
              <el-table-column :label="$t('courses.view')" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-view"
                    circle
                    @click="viewStudent(scope.row.student_id)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";

export default {
  name: "BackendData",
  components: {},
  mixins: [],
  // props: [],
  data() {
    return {
      students: []
    };
  },
  computed: {
    ...mapGetters("user", ["langValue", "profile"]),
    pathName() {
      return this.$route.params.pathName;
    }
  },
  watch: {},
  async mounted() {
    const data = await onlineCourseApi.parentStudentsList();
    if (data.status_code !== 0) {
      console.log(data);
      this.students = data;
    }
  },
  methods: {
    viewStudent(student_id) {
      console.log(student_id);
      this.$router.push({
        name: "studentProgress",
        query: { student_id: student_id }
      });
    }
  }
};
</script>

<style scoped>
.courses-title {
  margin: 20px 0;
}
.courses-list {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}
</style>
